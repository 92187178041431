import logo from './logo.svg';
import './App.css';
import Main from './Components/Main';

function App() {
  return (
    <div className="App">
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <Main />
    </div>
  );
}

export default App;
