import React, { useRef, useEffect } from 'react';
import firstImg from './Assets/DSC00138-min.JPG';
import secondImg from './Assets/DSC00092-min.JPG';
import thirdImg from './Assets/DSC00137-min.JPG';
import fourthImg from './Assets/DSC00043-min.JPG';
import ButtonMailto from './MailTo';
import FacebookLogo from './Assets/icons8-facebook-240.png'
import fifthImg from './Assets/Numerthrju1-min.jpg'
import sixthImg from './Assets/Numerthrju2-min.jpg'
import seventhImg from './Assets/Numerthrju3-min.jpg'

const Main = () => {
    const trackRef = useRef(null);
    const handleOnDown = e => trackRef.current.dataset.mouseDownAt = e.clientX;

    const handleOnUp = () => {
        trackRef.current.dataset.mouseDownAt = "0";
        trackRef.current.dataset.prevPercentage = trackRef.current.dataset.percentage;
    }

    const handleOnMove = e => {
        if (trackRef.current.dataset.mouseDownAt === "0") return;

        const mouseDelta = parseFloat(trackRef.current.dataset.mouseDownAt) - e.clientX,
            maxDelta = window.innerWidth / 2;

        const percentage = (mouseDelta / maxDelta) * -100,
            nextPercentageUnconstrained = parseFloat(trackRef.current.dataset.prevPercentage) + percentage,
            nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);

        trackRef.current.dataset.percentage = nextPercentage;

        trackRef.current.animate({
            transform: `translate(${nextPercentage}%, -50%)`
        }, { duration: 1200, fill: "forwards" });

        for (const image of trackRef.current.getElementsByClassName("image")) {
            image.animate({
                objectPosition: `${100 + nextPercentage}% center`
            }, { duration: 1200, fill: "forwards" });
        }
    };

    const moveImages = () => {
        requestAnimationFrame(() => {
            let currentPercentage = parseFloat(trackRef.current.dataset.percentage || "0");
            let nextPercentage = currentPercentage - 0.01;
            if (nextPercentage < -100) nextPercentage = 0;

            trackRef.current.dataset.percentage = nextPercentage;

            trackRef.current.style.transform = `translate(${nextPercentage}%, -50%)`;

            for (const image of trackRef.current.getElementsByClassName("image")) {
                image.style.objectPosition = `${100 + nextPercentage}% center`;
            }
        });
    };



    useEffect(() => {
        if (trackRef.current) {
            window.onmousedown = handleOnDown;
            window.ontouchstart = e => handleOnDown(e.touches[0]);
            window.onmouseup = handleOnUp;
            window.ontouchend = e => handleOnUp(e.changedTouches[0]);
            window.onmousemove = handleOnMove;
            window.ontouchmove = e => handleOnMove(e.touches[0]);
        }

        return () => {
            window.onmousedown = null;
            window.ontouchstart = null;
            window.onmouseup = null;
            window.ontouchend = null;
            window.onmousemove = null;
            window.ontouchmove = null;
        };
    }, []);

    return (
        <div className='main__container'>
            <div className='noise' />
            <div className="main__header">
                <div className="main__title">
                    Númer 3
                </div>

                <div className="main__socials">

                    <div className="main__insta">

                        <img
                            onClick={() => { location.href = 'https://www.instagram.com/numerthrju/' }}
                            style={{ width: "50px" }}
                            src='https://www.kortegaard.co.uk/wp-content/uploads/2020/06/best-solutions-of-instagram-png-transparent-png-images-unique-white-instagram-logo-outline-of-white-instagram-logo-outline-copy.png' />
                    </div>
                    <div className="main__facebook">
                        <img
                            onClick={() => { location.href = 'https://www.facebook.com/profile.php?id=61555611172360' }}
                            style={{ width: "50px" }}
                            src={FacebookLogo}
                            className='fblogo'
                        />
                    </div>

                </div>
            </div>
            <div ref={trackRef} id="image-track" data-mouse-down-at="0" data-prev-percentage="0">
                <img class="image" src={firstImg} draggable="false" />
                <img class="image" src={secondImg} draggable="false" />
                <img class="image" src={thirdImg} draggable="false" />
                <img class="image" src={fourthImg} draggable="false" />
                <img class="image" src={fifthImg} draggable="false" />
                <img class="image" src={sixthImg} draggable="false" />
                <img class="image" src={seventhImg} draggable="false" />
            </div>

            <div className="main__gap">

            </div>

            <div className="main__email__container">

                <div className="pointless">

                </div>
                <div
                    className="main__email"
                    onClick={() => { location.href = "mailto:numer3@numer3.com" }}  >
                    Bókanir á <span style={{ color: 'rgb(177, 177, 255)' }}>numer3@numer3.com</span>
                </div>
            </div>


        </div>
    );
};

export default Main;
